<template>
  <div class="cell">
    <div class="cell-top">
      <span @click="fold = !fold" class="title" v-html="content.title"></span>
      <span @click="handleClick" class="come-from">{{
        $t(`Search['${content.from}']`)
      }}</span>
    </div>
    <p class="content" v-html="content.text" :class="{ fold: fold }"></p>
  </div>
</template>

<script>
export default {
  props: ['content'],
  data() {
    return {
      fold: true
    }
  },
  methods: {
    handleClick() {
      switch (this.content.from) {
        case '电子钱包 > 使用说明':
          this.$router.push('/e-wallet#guide')
          break
        case '商户专区 > 常见问题':
          this.$router.push('/merchants#FAQs')
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cell {
  padding: 23px 49px;
  margin-bottom: 10px;
  background: #f7f7f7;
  border-radius: 6px;
  line-height: 1.5;
  .cell-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    span {
      cursor: pointer;
    }
    .come-from:hover {
      color: #666;
    }
  }
  .content {
    font-size: 14px;
    color: #333333;
    /deep/strong {
      display: block;
      font-weight: bold;
    }
    &.fold {
      max-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 23px 15px;
    .come-from {
      display: none;
    }
  }
}
</style>
