<template>
  <div class="pagenation" v-show="show">
    <div class="pagelist">
      <button
        class="jump arrow"
        :disabled="pstart"
        :class="{ disabled: pstart }"
        @click="
          {
            current_page--
          }
        "
      >
        &lt;
      </button>
      <button v-show="current_page > 5" class="jump" @click="jumpPage(1)">
        1
      </button>
      <span class="ellipsis" v-show="efont">...</span>
      <button
        class="jump"
        v-for="num in indexs"
        :key="num"
        :class="{ bgprimary: current_page == num }"
        @click="jumpPage(num)"
      >
        {{ num }}
      </button>
      <span class="ellipsis" v-show="ebehind">...</span>
      <button
        v-show="current_page < pages - 4"
        class="jump last-button"
        @click="jumpPage(pages)"
      >
        {{ pages }}
      </button>
      <button
        :class="{ disabled: pend }"
        :disabled="pend"
        class="jump arrow"
        @click="
          {
            current_page++
          }
        "
      >
        &gt;
      </button>

      <span class="jumppoint">{{ $t(`LastNews['跳转到']`) }}：</span>
      <input class="jumpinp" type="text" v-model="changePage" />
      <button class="jump gobtn arrow" @click="jumpPage(changePage)">GO</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
      default: 1
    }
  }, // pages: 50, 总页数
  data() {
    return {
      current_page: 1, // 当前页
      changePage: '' // 跳转页
    }
  },
  computed: {
    show: function () {
      return this.pages && this.pages !== 1
    },
    pstart: function () {
      return this.current_page === 1
    },
    pend: function () {
      return this.current_page === this.pages
    },
    efont: function () {
      if (this.pages <= 7) return false
      return this.current_page > 5
    },
    ebehind: function () {
      if (this.pages <= 7) return false
      var nowAy = this.indexs
      return nowAy[nowAy.length - 1] !== this.pages
    },
    indexs: function () {
      var left = 1
      var right = this.pages
      var ar = []
      if (this.pages >= 7) {
        if (this.current_page > 5 && this.current_page < this.pages - 4) {
          left = Number(this.current_page) - 3
          right = Number(this.current_page) + 3
        } else {
          if (this.current_page <= 5) {
            left = 1
            right = 7
          } else {
            right = this.pages

            left = this.pages - 6
          }
        }
      }
      while (left <= right) {
        ar.push(left)
        left++
      }
      return ar
    }
  },
  methods: {
    jumpPage: function (id) {
      let page = parseInt(id) || 1
      if (page < 1) page = 1
      if (page > this.pages) page = this.pages
      this.current_page = page
      this.changePage = ''
    },
    reset() {
      this.current_page = 1
    }
  },
  watch: {
    current_page() {
      this.$emit('currentChange', this.current_page)
    }
  }
}
</script>

<style>
.pagenation {
  /* text-align: center; */
  color: #666;
  margin: 20px auto 0;
  padding-top: 8px;
}
.pagelist {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.pagelist button {
  font-size: 14px;
}
.pagelist span,
.pagelist .jump,
.pagelist input {
  margin-bottom: 8px;
}
.pagelist .jump {
  border: 1px solid #e5e5e5;
  padding: 0 6px;
  margin-left: 8px;
  min-width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  color: #666;
  outline: none;
}

.jumpinp {
  width: 49px;
  height: 30px;
  margin-top: 0;
  font-size: 13px;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
  text-align: center;
}
.jumpinp:focus {
  border-color: #e60013;
}
.pagelist .jump:hover {
  color: #e60013;
}
.pagelist .jump.arrow {
  color: #666;
}
.pagelist .jump.arrow:active {
  border-color: #e60013;
  background: #e60013;
  color: #fff;
}

.pagelist .bgprimary {
  cursor: default;
  color: #fff !important;
  background: #e60013;
  border-color: #e60013;
}

.ellipsis {
  padding: 0px 8px;
}

.jumppoint {
  margin-left: 30px;
}

.pagelist .gobtn {
  font-size: 12px;
}

.bgprimary {
  cursor: default;
  color: #fff;
  background: #337ab7;
  border-color: #337ab7;
}
.pagelist .jump.disabled {
  background: #ddd;
  cursor: not-allowed;
}
</style>
