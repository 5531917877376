<template>
  <div class="search">
    <div class="banner">
      <p>{{ $t("Search['搜索']") }}</p>
      <input
        @change="search"
        v-model.trim="searchText"
        :placeholder="$t(`Search['请输入']`)"
        type="text"
      />
    </div>
    <div class="search-result container">
      <p v-show="!topSearchText" class="notice">
        {{ $t("Search['请在搜索框中输入您需要搜索的文字']") }}
      </p>
      <div class="res-box" v-show="topSearchText">
        <p v-if="$i18n.locale === 'zh'" class="num">
          搜索到{{ result.length }}个关于"{{ topSearchText }}"
        </p>
        <p v-else-if="$i18n.locale === 'kh'" class="num">
          {{ result.length }} លទ្ធផលនៃ"{{ topSearchText }}"
        </p>
        <p v-else class="num">
          {{ result.length }} results for "{{ topSearchText }}"
        </p>
        <Cell
          v-for="(item, i) in resultsWithPage"
          :content="item"
          :key="i"
        ></Cell>
        <Pagenation
          @currentChange="handlePageChange"
          ref="pagenation"
          :pages="totlePages"
          class="pagenation"
        ></Pagenation>
      </div>
    </div>
  </div>
</template>

<script>
import Cell from '../components/Cell'
import Pagenation from '@/components/Pagenation'
import problemEn from '../locales/en.json'
import problemZh from '../locales/zh.json'
import problemKh from '../locales/kh.json'
const problemDatabase = []
function getProblems(data, arr) {
  let i = 1
  while (data.EWallet[`FAQ_ForUser_problem_${i}`]) {
    arr.push({
      title: data.EWallet[`FAQ_ForUser_problem_${i}`],
      text: data.EWallet[`FAQ_ForUser_solution_${i}`],
      from: '电子钱包 > 使用说明'
    })
    i++
  }
  i = 1
  while (data.MerchantZone[`problem_${i}`]) {
    arr.push({
      title: data.MerchantZone[`problem_${i}`],
      text: data.MerchantZone[`solution_${i}`],
      from: '商户专区 > 常见问题'
    })
    i++
  }
}
getProblems(problemEn, problemDatabase)
getProblems(problemZh, problemDatabase)
getProblems(problemKh, problemDatabase)
export default {
  components: {
    Cell,
    Pagenation
  },
  computed: {
    totlePages() {
      return Math.ceil(this.result.length / 10)
    }
  },
  data() {
    return {
      noResult: true,
      searchText: '',
      topSearchText: '',
      searchList: null,
      result: [],
      resultsWithPage: [],
      content: {
        title: '开启优付帐户需要注册费用吗？',
        comeFrom: '电子钱包 > 使用说明',
        text:
          '不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。不需要，注册优付帐户是免费的。'
      }
    }
  },
  methods: {
    search() {
      const res = []
      const reg = new RegExp(this.searchText, 'gi')
      problemDatabase.forEach((val) => {
        if (reg.test(val.title) || reg.test(val.text)) {
          res.push({
            ...val,
            title: val.title.replace(reg, (metch, p, str) => {
              return str[p + 1] === '>' || str[p - 1] === '<'
                ? metch
                : '<span style="color:red">' + metch + '</span>'
            }),
            text: val.text.replace(reg, (metch, p, str) => {
              return str[p + 1] === '>' || str[p - 1] === '<'
                ? metch
                : '<span style="color:red">' + metch + '</span>'
            })
          })
        }
      })

      this.result = res
      this.topSearchText = this.searchText
      this.$refs.pagenation.reset()
      this.handlePageChange(1)
    },
    handlePageChange(curentPage) {
      this.resultsWithPage = this.result.filter((val, i) => {
        return i >= (curentPage - 1) * 10 && i < curentPage * 10
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  font-size: 18px;
  .container {
    max-width: 1100px;
  }
  .banner {
    height: 350px;
    text-align: center;
    background: linear-gradient(-30deg, #ff9459 0%, #ff3e47 100%);
    p {
      padding-top: 140px;
      margin-bottom: 39px;
      font-size: 40px;
      font-weight: 300;
      color: #ffffff;
      line-height: 1;
    }
    input {
      width: 500px;
      height: 50px;
      background: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 6px;
      line-height: 50px;
      text-align: center;
      outline: none;
      font-size: 18px;
    }
  }
  .search-result {
    min-height: 400px;
    .notice {
      padding-top: 130px;
      text-align: center;
      color: #333333;
    }
    .res-box {
      padding: 60px 0 90px;
      .num {
        margin-bottom: 30px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .pagenation {
    margin-top: 80px;
  }
  @media screen and (max-width: 768px) {
    .banner {
      input {
        width: 300px;
      }
    }
    .pagenation {
      margin-top: 50px;
    }
  }
}
</style>
